#root {
  background-position: bottom center;
  background-image: url(https://trezarcoin.com/wp-content/uploads/2020/02/Crypto_Illustration_07-1.png),linear-gradient(180deg,rgba(16,21,53,0) 50%,#101535 100%);
  background-color: rgba(0,0,0,0);
  min-height: 100vh;
}

body {
  background-image: url("https://trezarcoin.com/wp-content/uploads/2020/02/crypto-bg-02.png");
  background-color: #101535 !important;
}